import React, {useEffect, useState, useContext, useReducer} from "react";
import justcastApi from '../../api/justcast';
import {Context as MenuContext} from '../../context/MenuContext'
import { PhotoUpload, ArtworkUploadModal, TranscriptUpload } from "../../components/episode/assets";
import { ListGroup, ListGroupItem, Row, Card, CardBody } from "reactstrap";
import ToastMessage from "../../components/ToastMessage";

const transcriptTypes = {
  'transcript_json': '.json',
  'transcript_vtt': '.vtt',
  'transcript_srt': '.srt',
}

const EpisodeAssetsPage = (props) => {
  const {show_id, id} = props.match.params;
  const {setEpisodePageMenu} = useContext(MenuContext);
  const [loading, setLoading] = useState(true);
  const [pageError, setPageError] = useState(false);
  const [artworkModalOpen, setArtworkModalOpen] = useState(false);
  const [imageUploadErrors, setImageUploadErrors] = useState([]);
  const [artworkSrc, setArtworkSrc] = useState("https://via.placeholder.com/150") //
  const [artworkId, setArtworkId] = useState(null);
  const [toastShow, setToastShow] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [toastTitle, setToastTitle] = useState(null);
  const [toastTimeoutId, setToastTimeoutId] = useState(null);
  const [transcriptSrtUrl, setTranscriptSrtUrl] = useState(null)
  const [transcriptVttUrl, setTranscriptVttUrl] = useState(null)
  const [transcriptJsonUrl, setTranscriptJsonUrl] = useState(null)
  const [loadingChange, setLoadingChange] = useState(null);

  useEffect(() => {
    justcastApi.get(`/v2/shows/${show_id}/audioposts/${id}`)
    .then((res) => {
      setLoading(false);
      const data = res.data;
      const {artwork_file, artwork_id, transcript_srt} = data;
      if(transcript_srt) {
        setTranscriptSrtUrl(transcript_srt)
      }
      if(artwork_file) {
        setArtworkSrc(artwork_file)
        setArtworkId(artwork_id)
      }
      setEpisodePageMenu({
        title: "Assets",
        subtitle: `${data.show.podcast_title} > ${data.episode_title}`,
        currentPageId: "episodes",
        currentTabId: "assets",
        showName: data.show.podcast_title,
        showId: show_id,
        episodeId: id,
        landingPageUrl: data.show.landing_page_url,
        websiteUrl: data.show.player_page_link,
        rssFeed: data.show.rss_feed,
        passwordProtected: data.show.is_password_protected,
        advancedPrivate: data.show.is_advanced_private_feed,
        isPrivate: data.show.is_private,
        isPrivateShow:data.show.is_private_show,
        skill_podcast_website: data.show.skill_podcast_website,
        r2Uploaded: data.r2_uploaded
      })
    })
    .catch((err) => {
      setLoading(false)
      setPageError(true);
    })
  }, [])

  const handleSetToast = ({title, message}) => {
    setToastShow(true);
    setToastTitle(title)
    setToastMessage(message);

    if(toastTimeoutId) {
      clearTimeout(toastTimeoutId)
    };

    const _timeOutId = setTimeout(() => {
      setToastShow(false);
    }, 2000)

    setToastTimeoutId(_timeOutId)
  }

  const toggleToast = () => {
    setToastShow(!toastShow);
  }

  const toggleArtworkModal = () => {
    setArtworkModalOpen(!artworkModalOpen);
  }

  const setArtworkErrors = (errors) => {
    setImageUploadErrors(errors);
    setArtworkModalOpen(true);
  }

  const uploadArtwork = (file) => {
    setLoadingChange("artwork");
    const formData = new FormData();
    formData.append("name", "name");
    formData.append("type", "artwork");
    formData.append("file", file)

    justcastApi.post(`/v2/shows/${show_id}/audioposts/${id}/asset_files`, formData)
    .then((res) => {
      // alert("File upload sccuess");
      const { file_url, file_id } = res.data;
      if(file_url) {
        setArtworkSrc(file_url)
        setArtworkId(file_id)
      }

      handleSetToast({title: "Episode artwork", message: "Image upload success"})
      setLoadingChange(null)
    })
    .catch((err) => {
      handleSetToast({title: "Episode artwork", message: "Image upload failed"})
      setLoadingChange(null)
    })
  }

  const deleteArtwork = () => {
    setLoadingChange("artwork");
    justcastApi.delete(`/v2/shows/${show_id}/audioposts/${id}/asset_files?file_type=artwork`)
    .then((res) => {
      setArtworkSrc("https://via.placeholder.com/150")
      setArtworkId(null);
      handleSetToast({title: "Episode artwork", message: "Image delete success"})
      setLoadingChange(null);
    })
    .catch((err) => {
      console.log(err)
      setLoadingChange(null);
    })
  }

  const uploadTranscript = (file, fileType) => {
    setLoadingChange(fileType);

    const formData = new FormData();
    formData.append("name", "name");
    formData.append("type", fileType);
    formData.append("file", file)
    justcastApi.post(`/v2/shows/${show_id}/audioposts/${id}/asset_files`, formData)
    .then((res) => {
      setLoadingChange(null);
      const { file_url  } = res.data;
      if(file_url) {
        switch (fileType) {
          case 'transcript_json':
            setTranscriptJsonUrl(file_url);
            break;
          case 'transcript_srt':
            setTranscriptSrtUrl(file_url);
            break;
          case 'transcript_vtt':
            setTranscriptVttUrl(file_url);
            break;
          default:
            console.log('cannot find the right file type')
        }
      }

      handleSetToast({title: "Episode transcript", message: "Transcript upload success"})
    })
    .catch((err) => {
      setLoadingChange(null);
      handleSetToast({title: "Episode transcript", message: "Transcript upload failed"})
    })
  }

  const deleteFile = (fileType) => {
    setLoadingChange(fileType);

    justcastApi.delete(`/v2/shows/${show_id}/audioposts/${id}/asset_files?file_type=${fileType}`)
    .then((res) => {

      switch (fileType) {
        case 'transcript_json':
          setTranscriptJsonUrl(null);
          break;
        case 'transcript_srt':
          setTranscriptSrtUrl(null);
          break;
        case 'transcript_vtt':
          setTranscriptVttUrl(null);
          break;
        default:
          console.log('cannot find the right file type')
      }
      setLoadingChange(null);
      handleSetToast({title: "Episode transcript", message: "Transcript delete success"})
    })
    .catch((err) => {
      console.log(err)
      setLoadingChange(null);
    })
  }

  if(loading || pageError) return null;

  return (
    <div className="container-fluid">
      <ToastMessage
        toggle={toggleToast}
        show={toastShow}
        title={toastTitle}
        message={toastMessage}
      />
      <ArtworkUploadModal
        modal={artworkModalOpen}
        toggle={toggleArtworkModal}
        errors={imageUploadErrors}
      />
      <ListGroup>
        <ListGroupItem>
          <PhotoUpload
            src={artworkSrc}
            setArtworkModalOpen={setArtworkErrors}
            uploadFile={uploadArtwork}
            artworkId={artworkId}
            deleteFile={deleteArtwork}
            loading={loadingChange === "artwork"}
          />
        </ListGroupItem>
      </ListGroup>
      <hr className="my-5"/>
      <div>
        <Row className="justify-content-between align-items-center mb-5">
          <div className="col-12 col-md-9 col-xl-7">
            <h2 className="mb-2">Podcasting 2.0 Transcript</h2>
          </div>
          <div className="col-12 col-md-auto">
          <a className="btn btn-warning btn-sm" href="https://podcastindex.org/namespace/1.0#transcript" target="_blank">Learn more</a>
          </div>
        </Row>
        <Card>
          <CardBody>
            <ListGroup className="list-group-flush my-n3">
              <ListGroupItem>
                <TranscriptUpload
                  title="SRT"
                  description="The SRT format was designed for video captions but provides a suitable solution for podcast transcripts."
                  learnMoreHref="https://github.com/Podcastindex-org/podcast-namespace/blob/main/transcripts/transcripts.md#srt"
                  uploadFile={uploadTranscript}
                  fileType="transcript_srt"
                  fileFormat={transcriptTypes["transcript_srt"]}
                  fileHref={transcriptSrtUrl}
                  deleteFile={deleteFile}
                  loading={loadingChange === "transcript_srt"}
                />
              </ListGroupItem>
              <ListGroupItem>
                <TranscriptUpload
                  title="WebVTT"
                  description="Web Video Text Tracks Format (WebVTT) are an alternative to SRT primarily designed for the use in HTML on the web."
                  learnMoreHref="https://github.com/Podcastindex-org/podcast-namespace/blob/main/transcripts/transcripts.md#webvtt"
                  uploadFile={uploadTranscript}
                  fileType="transcript_vtt"
                  fileFormat={transcriptTypes["transcript_vtt"]}
                  fileHref={transcriptVttUrl}
                  deleteFile={deleteFile}
                  loading={loadingChange === "transcript_vtt"}
                />
              </ListGroupItem>
              <ListGroupItem>
                <TranscriptUpload
                  title="JSON"
                  description="The JSON representation is a flexible format that accomodates various degrees of fidelity in a concise way."
                  learnMoreHref="https://github.com/Podcastindex-org/podcast-namespace/blob/main/transcripts/transcripts.md#json"
                  uploadFile={uploadTranscript}
                  fileType="transcript_json"
                  fileFormat={transcriptTypes["transcript_json"]}
                  fileHref={transcriptJsonUrl}
                  deleteFile={deleteFile}
                  loading={loadingChange === "transcript_json"}
                />
              </ListGroupItem>
            </ListGroup>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default EpisodeAssetsPage