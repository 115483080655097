import React, { useRef } from 'react'
import { Row, Col, Spinner } from 'reactstrap'
import TranscriptNewButton from './TranscriptNewButton'
import TranscriptButtonGroup from './TranscriptButtonGroup'

const buttonLookup = {
  'uploaded': TranscriptButtonGroup,
  'new': TranscriptNewButton,
  'loading': Spinner
};

export default function TranscriptUpload({
  title,
  description,
  learnMoreHref,
  uploadFile,
  deleteFile,
  fileType,
  fileFormat,
  fileHref,
  loading,
}) {
  const hiddenFileInput = useRef(null);
  const status = loading ? 'loading' : fileHref ? 'uploaded' : 'new';
  const TranscriptButton = buttonLookup[status];

  const handleChange = (event) => {
    if(event.target.files[0]) {
      uploadFile(event.target.files[0], fileType)
    }
  }

  const handleRemove = () => {
    deleteFile(fileType)
  }

  const handleClick = () => {
    hiddenFileInput.current.click();
  }

  if(!fileFormat) return null;

  return (
    <Row className='align-items-center'>
      <Col>
        <h4 className='mb-1'>{title}</h4>
        <small>{description} <a href={learnMoreHref} target='_blank'>Learn more</a></small>
      </Col>
      <div className='col-auto'>
        <TranscriptButton
          href={fileHref}
          handleRemove={handleRemove}
          handleClick={handleClick}
        >
        </TranscriptButton>
        <input
          accept={fileFormat}
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{display: 'none'}}/>
      </div>
    </Row>
  )
}
