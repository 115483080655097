import React, {useEffect, useState, useContext} from "react";
import { useAtom } from "jotai";
import { Link } from "react-router-dom";
import { Button, Form, InputGroup, InputGroupAddon, Input, Spinner, CardBody, CardHeader, FormGroup, Label} from 'reactstrap';
import {Context as ScreenContext} from '../context/ScreenContext'
import {Context as AlertContext} from '../context/AlertContext'
import {Context as MenuContext} from '../context/MenuContext'
import justcastApi from '../api/justcast';
import WidgetPlayerColorPicker from '../components/WidgetPlayerColorPicker'
import PlaylistWidget from '../components/PlaylistWidget'
import SingleEpisodeWidget from '../components/SingleEpisodeWidget'
import MobilePlayerWidget from '../components/MobilePlayerWidget'
import LatestEpisodeWidget from '../components/LatestEpisodeWidget'
import WidgetSettingForm from '../components/WidgetSettingForm'
import WidgetAllowSiteSetting from '../components/WidgetAllowSiteSetting'
import ScreenBlocker from '../components/ScreenBlocker'
import {updatePlayerConfigAtom} from '../jotai'
import defaultImg from './../assets/img/avatars/teams/team-logo-5.jpg'

const imgStyle = {
  maxWidth: '2.5rem'
}

const Widgets = (props) => {
  const id = props.match.params.id;

  const [screenBlocker, setScreenBlocker] = useState(false);
  const [playerConfigs, setPlayerConfigs] = useAtom(updatePlayerConfigAtom)
  const screenContext = useContext(ScreenContext);
  const {loading} = screenContext.state;
  const menuContext = useContext(MenuContext);
  const {addWithTimeout} = useContext(AlertContext);
  const [showName, setShowName] = useState('')
  const [show, setShow] = useState({});
  const [audioposts, setAudioposts] = useState([]);
  const [allowSites, setAllowSites] = useState([]);
  const [allowSite, setAllowSite] = useState('')
  const [initBlockWidget, setInitBlockWidget] = useState()
  const [episodes, setEpisodes] = useState([]);
  const [latestEpisode, setLatestEpisode] = useState([]);

  const handleToggleChange = (event) => {
    const key = event.target.name;
    const value = event.target.checked;
    setShow({...show, [key]: value})
    const dict = {
      hide_widget_pub_date: "hidePubDate",
      hide_more_info_from_widget: "hideMoreInfo",
      playlist_full_height: "playlistFullHeight"
    }
    const name = dict[key];
    setPlayerConfigs({[name]: value})
  }

  const handleUpdate = () => {

    const colors = {
      widget_primary_background_color: playerConfigs.primaryBackgroundColor,
      widget_primary_button_color: playerConfigs.primaryButtonColor,
      widget_primary_text_color: playerConfigs.primaryTextColor,
      widget_progress_bar_filled_color: playerConfigs.progressBarFilledColor,
      widget_progress_bar_background_color: playerConfigs.progressBarBackgroundColor,
      widget_playlist_background_color: playerConfigs.playlistBackgroundColor,
      widget_playlist_text_color: playerConfigs.playlistTextColor,
      widget_chapter_background_color: playerConfigs.chapterBackgroundColor,
      widget_chapter_text_color: playerConfigs.chapterTextColor
    }

    const _show = {...show, ...colors}

    justcastApi.put(`/v1/shows/${id}`, _show)
    .then((res) => {
      window.scrollTo(0, 0);
      // add the updated data to config atom
      setPlayerConfigs({
        hidePubDate: res.data.hide_widget_pub_date,
        hideMoreInfo: res.data.hide_more_info_from_widget,
        primaryBackgroundColor: res.data.widget_primary_background_color ?  res.data.widget_primary_background_color : "#0c1824",
        primaryButtonColor: res.data.widget_primary_button_color ?  res.data.widget_primary_button_color : "#f7f8f9",
        primaryTextColor: res.data.widget_primary_text_color ?  res.data.widget_primary_text_color : "#f7f8f9",
        progressBarFilledColor: res.data.widget_progress_bar_filled_color ?  res.data.widget_progress_bar_filled_color : "#f7f8f9",
        progressBarBackgroundColor: res.data.widget_progress_bar_background_color ?  res.data.widget_progress_bar_background_color : "#8A8175",
        playlistBackgroundColor: res.data.widget_playlist_background_color ?  res.data.widget_playlist_background_color : "#30343c",
        playlistTextColor: res.data.widget_playlist_text_color ?  res.data.widget_playlist_text_color : "#f7f8f9",
        chapterBackgroundColor: res.data.widget_chapter_background_color ?  res.data.widget_chapter_background_color : "#30343c",
        chapterTextColor:  res.data.widget_chapter_text_color ?  res.data.widget_chapter_text_color : "#f7f8f9"
      })
      addWithTimeout({color: 'success', message: `Widget setting has been updated!`, isOpen: true, timeout: 3000})
    })
    .catch((err) => {
      console.log(err)
      addWithTimeout({color: 'danger', message: 'Error', isOpen: true, timeout: 3000})
    })
  }

  const handleColorUpdate = () => {

    const colors = {
      widget_primary_background_color: playerConfigs.primaryBackgroundColor,
      widget_primary_button_color: playerConfigs.primaryButtonColor,
      widget_primary_text_color: playerConfigs.primaryTextColor,
      widget_progress_bar_filled_color: playerConfigs.progressBarFilledColor,
      widget_progress_bar_background_color: playerConfigs.progressBarBackgroundColor,
      widget_playlist_background_color: playerConfigs.playlistBackgroundColor,
      widget_playlist_text_color: playerConfigs.playlistTextColor,
      widget_chapter_background_color: playerConfigs.chapterBackgroundColor,
      widget_chapter_text_color: playerConfigs.chapterTextColor
    }

    const _show = {...show, ...colors}
    justcastApi.put(`/v1/shows/${id}`, _show)
    .then((res) => {
      // add the updated data to config atom
      addWithTimeout({color: 'success', message: `Widget colors has been updated!`, isOpen: true, timeout: 3000})
    })
    .catch((err) => {
      console.log(err)
      addWithTimeout({color: 'danger', message: 'Error', isOpen: true, timeout: 3000})
    })
  }

  const updateColorsToAllShow = () => {
    const colors = {
      widget_primary_background_color: playerConfigs.primaryBackgroundColor,
      widget_primary_button_color: playerConfigs.primaryButtonColor,
      widget_primary_text_color: playerConfigs.primaryTextColor,
      widget_progress_bar_filled_color: playerConfigs.progressBarFilledColor,
      widget_progress_bar_background_color: playerConfigs.progressBarBackgroundColor,
      widget_playlist_background_color: playerConfigs.playlistBackgroundColor,
      widget_playlist_text_color: playerConfigs.playlistTextColor,
      widget_chapter_background_color: playerConfigs.chapterBackgroundColor,
      widget_chapter_text_color: playerConfigs.chapterTextColor
    }

    justcastApi.put(`/v1/update_all_shows`, colors)
    .then((res) => {
      // add the updated data to config atom
      addWithTimeout({color: 'success', message: `Widget colors has been updated!`, isOpen: true, timeout: 3000})
    })
    .catch((err) => {
      console.log(err)
      addWithTimeout({color: 'danger', message: 'Error', isOpen: true, timeout: 3000})
    })
  }

  const addAllowSite = () => {
    if(initBlockWidget !== show.block_widget && show.block_widget === true) {
      handleUpdate()
    }
    justcastApi.post(`/v1/shows/${id}/allow_sites`, {url_address: allowSite})
    .then((res) => {
      setAllowSite('')
      const data = res.data;
      setAllowSites([data, ...allowSites]);
      // console.log(res.data)
    })
    .catch((err) => {
      console.log(err)
    })
  }

  const deleteAllowSite = (allow_site_id) => {
    justcastApi.delete(`/v1/shows/${id}/allow_sites/${allow_site_id}`)
    .then((res) => {
      const deletedAllowSite = res.data;
      const _allowSites = allowSites.filter(_allowSite => _allowSite.id !== deletedAllowSite.id)
      setAllowSites(_allowSites);
    })
    .catch((err) => {
      console.log(err)
    })
  }

  useEffect(() => {
    screenContext.startFetching();

    justcastApi.get(`/v2/shows/${id}/widget`)
    .then((res) => {
      setShowName(res.data.show.podcast_title)
      setShow(res.data.show)
      // setAudioposts(res.data.audioposts)
      setInitBlockWidget(res.data.show.block_widget ? res.data.show.block_widget : false);
      screenContext.finishFetching();

      setScreenBlocker(res.data.show.skill_embedded_player);

      menuContext.setEpisodesMenus({
        title: 'Widgets',
        subtitle: res.data.show.podcast_title,
        currentPageId: 'episodes',
        currentTabId: 'widgets',
        showId: id,
        landingPageUrl: res.data.show.landing_page_url,
        websiteUrl: res.data.show.player_page_link,
        rssFeed: res.data.show.rss_feed,
        passwordProtected: res.data.show.is_password_protected,
        advancedPrivate: res.data.show.is_advanced_private_feed,
        isPrivate: res.data.show.is_private,
        isPrivateShow: res.data.show.is_private_show,
        skill_podcast_website: res.data.show.skill_podcast_website,
        isDirectUpload: res.data.show.is_direct_upload,
        shopifyApp: res.data.is_shopify_app
      });

      const items = [];
      const feedArtwork = res.data.show.artwork_url;
      const podcastTitle = res.data.show.podcast_title;
      if(res.data.audioposts.length > 0) {
        res.data.audioposts.forEach((item) => {
          let artworkUrl = feedArtwork;
          if(item.artwork_url) {
            artworkUrl = item.artwork_url;
          }

          const chaptersUrl = item.chapters_url
          items.push({
            id: item.id,
            showId: item.show_id,
            title: item.name,
            name: item.name,
            description: item.description,
            podcastTitle: podcastTitle,
            artworkUrl: artworkUrl,
            pubDate: item.audio_date,
            link: item.single_page_url,
            audioUrl: item.audio_src,
            chaptersUrl: chaptersUrl,
          })
        })
        setEpisodes(items);
        setLatestEpisode([items[0]])
      }
      const configs = {
        hidePubDate: res.data.show.hide_widget_pub_date,
        hideMoreInfo: res.data.show.hide_more_info_from_widget,
        playlistFullHeight: res.data.show.playlist_full_height,
        primaryBackgroundColor: res.data.show.widget_primary_background_color ?  res.data.show.widget_primary_background_color : "#0c1824",
        primaryButtonColor: res.data.show.widget_primary_button_color ?  res.data.show.widget_primary_button_color : "#f7f8f9",
        primaryTextColor: res.data.show.widget_primary_text_color ?  res.data.show.widget_primary_text_color : "#f7f8f9",
        progressBarFilledColor: res.data.show.widget_progress_bar_filled_color ?  res.data.show.widget_progress_bar_filled_color : "#f7f8f9",
        progressBarBackgroundColor: res.data.show.widget_progress_bar_background_color ?  res.data.show.widget_progress_bar_background_color : "#8A8175",
        playlistBackgroundColor: res.data.show.widget_playlist_background_color ?  res.data.show.widget_playlist_background_color : "#30343c",
        playlistTextColor: res.data.show.widget_playlist_text_color ?  res.data.show.widget_playlist_text_color : "#f7f8f9",
        chapterBackgroundColor: res.data.show.widget_chapter_background_color ?  res.data.show.widget_chapter_background_color : "#30343c",
        chapterTextColor:  res.data.show.widget_chapter_text_color ?  res.data.show.widget_chapter_text_color : "#f7f8f9"
      }
      setPlayerConfigs(configs)
    })
    .catch((err) => {
      screenContext.finishFetching();
      console.log(err)
    })

    justcastApi.get(`/v1/shows/${id}/allow_sites`)
    .then((res) => {
      setAllowSites(res.data)
    })
    .catch((err) => {
      console.log(err)
    })
  },[id]);

  if(loading) {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <Spinner color="primary" />
          </div>
        </div>
      </div>
    )
  }

  if(screenBlocker === false) {
    return (
      <ScreenBlocker message="Please upgrade your account to access the data."/>
    )
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card card-body">
              <PlaylistWidget
                is_advanced_private_feed={show.is_advanced_private_feed ? show.is_advanced_private_feed : false}
                showId={id}
                episodes={episodes}
                allowSite={allowSites[0]?.url_address}
              />
              <hr className="my-5"/>
              <LatestEpisodeWidget
                is_advanced_private_feed={show.is_advanced_private_feed ? show.is_advanced_private_feed : false}
                showId={id}
                episodes={latestEpisode}
                allowSite={allowSites[0]?.url_address}
              />
              <hr className="my-5"/>
              <SingleEpisodeWidget
                is_advanced_private_feed={show.is_advanced_private_feed ? show.is_advanced_private_feed : false}
                showId={id}
                audioposts={episodes}
                allowSite={allowSites[0]?.url_address}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <CardHeader>
                <h3>Player settings</h3>
              </CardHeader>
              <CardBody>
                <WidgetSettingForm
                  hide_widget_pub_date={show.hide_widget_pub_date ? show.hide_widget_pub_date : false}
                  hide_widget_subscribe={show.hide_widget_subscribe ? show.hide_widget_subscribe : false}
                  hide_widget_share={show.hide_widget_share ? show.hide_widget_share : false}
                  is_advanced_private_feed={show.is_advanced_private_feed ? show.is_advanced_private_feed : false}
                  block_widget={show.block_widget ? show.block_widget : false}
                  playlist_full_height={show.playlist_full_height ? show.playlist_full_height : false}
                  hide_more_info_from_widget={show.hide_more_info_from_widget ? show.hide_more_info_from_widget : false}
                  handleToggleChange={handleToggleChange}
                  handleSubmit={handleUpdate}
                >
                  <FormGroup>
                    <Label className="mb-1">Custom Colors</Label>
                    <WidgetPlayerColorPicker/>
                  </FormGroup>
                </WidgetSettingForm>

                {/* below one is kinda cool, UI to control if the widget able to be embed on certain website*/}
                {
                  show.is_advanced_private_feed ?
                  <div>
                    <hr/>
                    <div className="row">
                      <h2 className="col-12">Widget security code</h2>
                    </div>
                    <Form onSubmit={(event) => {
                      event.preventDefault()
                      addAllowSite()
                    }}>
                      <p>To embed widget, you will need to add a widget code to the end of the URL</p>
                      <InputGroup>
                        <Input placeholder="abcde"
                          value={allowSite}
                          onChange={(e) => {
                            setAllowSite(e.target.value)
                          }}
                        />
                        <InputGroupAddon addonType="append">
                          <Button color="primary" onClick={addAllowSite}>
                            Add code
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </Form>
                    <br/>
                    <p>e.g. {`${process.env.REACT_APP_LANDING_PAGE}/widget/${id}/episodes?widget_code=${allowSite ? allowSite : "abcde"}`}</p>
                    <hr/>
                    <WidgetAllowSiteSetting
                      blockWidget={show.is_advanced_private_feed ? show.is_advanced_private_feed : false}
                      allowSites={allowSites}
                      handleAllowSiteDeleted={deleteAllowSite}
                    />
                  </div> : null
                }
                <hr className="mt-5 mb-5"/>
                <div className="btn btn-block btn-primary" onClick={handleUpdate}>Save change</div>
                <Link to={`/shows/${id}/episodes`} className="btn btn-block btn-link text-muted">Cancel this change</Link>
              </CardBody>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Widgets;