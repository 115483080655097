import React from "react";
import { Form, FormGroup, Input, Button, Label } from 'reactstrap';
import FormMutedText from './FormMutedText'

const SelectionOptions = ({options, inputLabel, optionLabel, optionId}) => {  
  return (
    options.map((item) => 
      <option 
      key={inputLabel+item[optionId].toString()} 
      value={item[optionId]} >{item[optionLabel]}</option>
    )
  )
}

const FromGroupInputSelect = ({
  inputLabel, 
  inputType, 
  inputName, 
  inputPlaceholder, 
  inputValue, 
  inputClassName, 
  handleInputChange,
  options,  
  optionLabel,
  optionId,
  mutedText}) => {
  return (
    <FormGroup>
      <Label>{inputLabel}</Label>      
      <FormMutedText text={mutedText}/>
      <Input
        className={inputClassName}
        type={inputType}
        name={inputName}
        value={inputValue ? inputValue : -10}
        placeholder={inputPlaceholder}
        onChange={handleInputChange}
      >
        <SelectionOptions options={options} inputLabel={inputLabel} optionLabel={optionLabel} optionId={optionId}/>
      </Input>      
    </FormGroup>
  )
}

export default FromGroupInputSelect;